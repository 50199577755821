<template>
  <div>
    <div class="formgrid p-fluid grid justify-content-start align-items-center">
      <Divider class="m-2 mb-5"></Divider>
      <div class="field col-12 sm:col-6">
        <label
          for="name"
          style="color: #687a8d !important"
        >Cost Type</label>
        <div>
          {{ cost?.type_name }}
        </div>
      </div>
      <div class="field col-12 sm:col-6">
        <label
          for="name"
          style="color: #687a8d !important"
        >Supplier</label>
        <div>
          {{ cost?.supplier.company_name }}
        </div>
      </div>
      <div class="field col-12 sm:col-6">
        <label
          for="name"
          style="color: #687a8d !important"
        >Cost Name</label>
        <div v-if="cost?.title">
          {{ cost?.title }}
        </div>
        <div
          class="font-light"
          v-else
        >N/A</div>
      </div>
      <div class="field col-12 sm:col-6">
        <label
          for="name"
          style="color: #687a8d !important"
        >Markup Method</label>
        <div>
          {{ getMarkupMethod(cost?.markup_method.uid) }}
        </div>
      </div>
      <div class="field col-12 sm:col-12">
        <label
          for="name"
          style="color: #687a8d !important"
        >Description</label>
        <div
          v-if="cost"
          v-html="markdownDescription"
          class="mb-3"
        ></div>
      </div>
    </div>
    <div>
      <div class="flex pb-3">
        <div class="text-xl col pl-0">Cost Detail</div>
      </div>
      <div class="grid col-12 cursor-pointer pl-0">
        <div class="field col">
          <label
            for="name"
            style="color: #687a8d !important"
          >Units</label>
          <div>
            {{ cost?.quantity }}
          </div>
        </div>
        <div class="field col">
          <label
            for="name"
            style="color: #687a8d !important"
          >Unit Cost</label>
          <div>
            {{ cost?.unit_price }}
          </div>
        </div>
        <div class="field col">
          <label
            for="name"
            style="color: #687a8d !important"
          >Markup</label>
          <div>
            {{ cost?.markup ?? cost?.margin
            }}{{ cost?.markup_method?.uid === "per" ? "%" : "" }}
          </div>
        </div>
        <div class="field col">
          <label
            for="name"
            style="color: #687a8d !important"
          >Unit Price</label>
          <div>
            {{ cost?.price }}
          </div>
        </div>
        <div class="field col">
          <label
            for="name"
            style="color: #687a8d !important"
          >Total Price</label>
          <div>
            {{ cost?.quantity * cost?.price }}
          </div>
        </div>
      </div>

      <div
        class="field mt-3 font-light"
        v-if="cost?.files && cost?.files.length > 0"
      >
        Documents
      </div>
      <div
        v-for="(f, index) in cost.files"
        :key="f.uid"
        :class="'field  ' + (index === 0 ? 'mt-3' : '')"
      >
        <Attachment :file="f"></Attachment>
      </div>
    </div>
  </div>
</template>

<script>
import { state } from "../../../services/data_service";
import Attachment from "@/application/Tasks/components/Attachment";
export default {
  props: {
    cost: {
      type: Object,
    },
  },
  components: {
    Attachment,
  },
  async mounted() {},
  methods: {
    getMarkupMethod(method) {
      if (method) {
        return this.taskTypePricing?.find((m) => m.uid == method)?.name;
      }
    },
  },
  computed: {
    markdownDescription() {
      return this.cost?.description
        ?.replace(/<\/p><p>/g, "<br>") // Replace all </p><p> with <br>
        .replace(/<p>/g, "") // Remove all <p> tags
        .replace(/<\/p>/g, "") // Remove all </p> tags
        .replace(/\n/g, "<br>"); // Replace all newlines with <br>
    },
    taskTypePricing() {
      return state.taskTypePricing;
    },
  },
};
</script>

<style>
</style>